<template>
  <div>
    <div class="content content-full content-boxed">
      <div v-show="errorAction.create" class="alert alert-danger">{{ errorAction.uploadCsv }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <h3>CSV Import</h3>
          <div v-if="!hasUploaded">
            <p>
              Use this tool to upload account data from a CSV file. Please ensure your CSV file uses headings as defined in
              <a href="#">this template</a>.
            </p>
            <div class="form-group">
              <input type="file" name="csv" accept=".csv" @change="onFileSelect" />
            </div>
            <div class="text-right pb-4">
              <button type="submit" class="btn btn-primary" :disabled="loadingAction.uploadCsv" @click="onSubmit">
                <span v-if="loadingAction.uploadCsv"><i class="fa fa-spin fa-spinner mr-1"></i> Uploading...</span>
                <span v-else><i class="fa fa-upload mr-1"></i> Upload</span>
              </button>
            </div>
          </div>
          <div v-else-if="results.error">
            <div class="alert alert-danger mb-3">
              <i class="fa fa-fw fa-times-circle"></i> <strong>Import failed.</strong>
              {{ parseError(results.error) }}
            </div>
            <div class="btn btn-danger btn-block mb-3" @click="onClickUndo">Undo import</div>
          </div>
          <div v-else-if="results.accounts">
            <div class="btn btn-danger mb-3" @click="onClickUndo">Undo import</div>
            <div class="alert alert-success mb-3">
              <i class="fa fa-fw fa-check-circle"></i> <strong>Import completed successfully.</strong> {{ results.accounts.length }} out of
              {{ results.totalRows }} rows were imported.
            </div>

            <h4>Successful Imports</h4>
            <table v-if="results.accounts && results.accounts.length > 0" class="table table-vcenter table-responsive">
              <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Account Name</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>MPN</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(account, index) in results.accounts" :key="account._id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ account.name }}</td>
                  <td class="text-capitalize">{{ account.status }}</td>
                  <td class="text-capitalize">{{ account.type }}</td>
                  <td>{{ account.meterPointNumber }}</td>
                </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-warning mb-4" role="alert">
              <p class="mb-0">No accounts were successfully imported.</p>
            </div>

            <h4 class="text-danger">Bad Imports</h4>
            <table v-if="results.badAccounts && results.badAccounts.length > 0" class="table table-vcenter table-responsive">
              <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Account Name</th>
                  <th>Type</th>
                  <th>MPN</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(account, index) in results.badAccounts" :key="account._id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ account.name }}</td>
                  <td>{{ account.type }}</td>
                  <td>{{ account.meterPointNumber }}</td>
                  <td>{{ account.importError }}</td>
                </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-warning mb-4" role="alert">
              <p class="mb-0"><i class="fa fa-check-circle mr-2"></i> No accounts failed to import.</p>
            </div>

            <div class="text-right pb-4">
              <router-link class="btn btn-primary" :to="{ name: 'account-list' }">Go to accounts</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AccountCsvImport',
  data() {
    return {
      file: {},
      hasUploaded: false,
      results: {},
      form: {
        mpanMatchType: 'full'
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'account/loadingAction',
      errorAction: 'account/errorAction'
    })
  },
  methods: {
    ...mapActions({
      uploadCsv: 'account/uploadCsv',
      uploadCsvRollback: 'account/uploadCsvRollback',
      listAccounts: 'account/list'
    }),
    parseError(error) {
      let niceError = error;

      if (error.path && error.stringValue) {
        niceError = `A row contains an invalid "${error.path}": ${error.stringValue}`;
      } else if (error.message) {
        niceError = error.message;
      }

      return niceError;
    },
    onFileSelect(e) {
      const { name, files } = e.target;

      const formData = new FormData();

      formData.append(name, files[0], files[0].name);

      this.file = {
        name: files[0].name,
        form: formData
      };
    },
    async onSubmit() {
      this.hasUploaded = false;
      this.results = [];

      try {
        this.results = await this.uploadCsv({ data: this.file.form, params: this.form });
      } catch (e) {
        this.results = {};
      }

      this.hasUploaded = true;
    },
    async onClickUndo() {
      await this.uploadCsvRollback({ batchId: this.results.batchId });

      this.hasUploaded = false;
      this.file = {};
      this.results = [];
    }
  }
};
</script>
